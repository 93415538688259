// MeetingDetails.js
import React, { useState, useEffect, useRef } from "react";
import "./MeetingDetails.css"; // Import the CSS file
import Textarea from "@mui/joy/Textarea";
import { useNavigate } from "react-router-dom";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import axios from "axios";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { MAIN_URL } from "../../Constants/constants.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MeetingDetails = ({ meetingId }) => {
  const [meetingData, setMeetingData] = useState(null);
  const [currentNotes, setCurrentNotes] = useState();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let response = await axios.get(
        MAIN_URL + "api/meeting/meetingByID/" + meetingId,
        {
          headers: {
            Accept: "application/json",
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      response.data.notes = await JSON.parse(response.data.notes);
      setMeetingData(response.data);
      setCurrentNotes(response.data.notes);
      setShowLoader(true);
    }
    fetchData();
  }, []);

  const handleSave = (currentNotesToBeSaved) => {
    axios
      .post(
        MAIN_URL + "api/meeting/updateMeetingDetail/" + meetingId,
        { notes: JSON.stringify(currentNotesToBeSaved) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Notes Saved!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      })
      .catch((error) => {
        toast.error("Please Try Again!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  const renderSection = (sectionKey) => {
    let section = currentNotes[sectionKey];
    if (Array.isArray(section)) {
      section = section.reduce(
        (item, curitem) => (item += "- " + curitem + "\n"),
        ""
      );
    }

    return (
      <div className="meetingsDetialsBodyCard">
        <h3>
          <LightbulbIcon sx={{ margin: "0 0.5% 0 0", color: "#151125" }} />
          {sectionKey}:
        </h3>
        <div>
          {
            <Textarea
              size="lg"
              variant="plain"
              onChange={(e) => handleSectionChange(e, sectionKey)}
              value={section}
              sx={{ color: "#141125", paddingTop: "0px" }}
            />
          }
        </div>
      </div>
    );
  };

  const handleSectionChange = (e, sectionKey) => {
    setCurrentNotes({ ...currentNotes, [sectionKey]: e.target.value });
  };

  return (
    <div className="fullMeetingDetailContainer">
      <ToastContainer />
      {!showLoader ? (
        <div className="clientLoaderContainer">
          <CircularProgress sx={{ margin: "0 auto", color: "#C57F8F" }} />
        </div>
      ) : (
        <div className="meetingsDetialsContainer">
          <div className="meetingsDetialsHeader">
            <div className="meetingsDetialsHeaderTimeSection">
              <h1>{meetingData.meetingName}</h1>
              <div
                className="saveButton"
                onClick={() => {
                  handleSave(currentNotes);
                }}
              >
                Save
              </div>
            </div>
            <div>
              <hr
                className="meetingsDetialsHeaderLineBreak"
                color="#fffff5"
              ></hr>
            </div>
            <div className="meetingsDetialsHeaderTimeSection">
              <span>Duration: {meetingData.duration}</span>
              <span>Created at: {meetingData.time}</span>
            </div>
          </div>
          <div className="meetingsDetialsBodyContainer">
            {Object.keys(currentNotes).map((key) => (
              <div key={key}>{renderSection(key)}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingDetails;
