import React, { useEffect, useState } from "react";
import "./RecordingSection.css";
import "../Utils/SidePanel/SidePanel.css";
import { init } from "vanilla-tilt";
import { useNavigate } from "react-router-dom";
import SidePanel from "../Utils/SidePanel/SidePanel.jsx";
import "./ClientSection.css";
import ClientCard from "../Utils/ClientCard/ClientCard.jsx";
import NewClientModal from "../Utils/Modals/NewClientModal.jsx";
import nothingShow from "../../Resources/nothing.svg";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import axios from "axios";
import { MAIN_URL } from "../../Constants/constants.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClientsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  // let clientsData=[];

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == null || jwtToken == undefined) {
      navigate("/login", { replace: true });
    } else {
      let userID = getUserIdFromJWT(jwtToken);
      async function fetchData() {
        let response = await axios.get(
          MAIN_URL + "api/client/clientByUserID/" + userID,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setClientsData(response.data);
        setShowLoader(true);
      }
      fetchData();
    }
  }, [isModalOpen]);

  const handleNewClientSubmit = async (clientData) => {
    let jwtToken = localStorage.getItem("noNameJWT");
    let userID;

    if (jwtToken != null) {
      userID = getUserIdFromJWT(jwtToken);
    } else if (jwtToken == null) {
      navigate("/login");
    }

    clientData = { ...clientData, userID: userID };
    try {
      let response = await axios.post(
        MAIN_URL + "api/client/addClient",
        clientData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status == 200) window.location.reload(false);
    } catch (e) {
      toast.error(e.response.data.error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className="recordingSection-app-container">
      <ToastContainer />
      <SidePanel currentActive={"Clients"}></SidePanel>
      <div className="main-content">
        {" "}
        <div className="clients-page">
          <header className="clients-header">
            <button
              className="new-client-btn"
              onClick={() => setIsModalOpen(true)}
            >
              + New Client
            </button>
          </header>

          {!showLoader ? (
            <div className="clientLoaderContainer">
              <CircularProgress sx={{ margin: "0 auto", color: "#C57F8F" }} />
            </div>
          ) : clientsData.length != 0 ? (
            <div className="client-list">
              {clientsData.map((client, index) => (
                <ClientCard key={index} {...client} />
              ))}
            </div>
          ) : (
            <div className="nothingToShowImage">
              <img src={nothingShow}></img>
              <h1>
                Confused? Don't worry just look at the top left corner! And
                There You Go.
              </h1>
            </div>
          )}
        </div>
      </div>
      <NewClientModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleNewClientSubmit}
      />
    </div>
  );
};

export default ClientsSection;
