import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MAIN_URL } from "../../Constants/constants.jsx";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadAudio = () => {
  const [userID, setUserID] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [fileName, setFileName] = useState("Select File");
  const navigate = useNavigate();

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == null || jwtToken == undefined) {
      navigate("/login", { replace: true });
    } else {
      let userID = getUserIdFromJWT(jwtToken);
      setUserID(userID);
    }
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!audioFile) {
      alert("Please select an audio file");
      return;
    }

    axios
      .get(
        "https://aiforall-5psafurj4q-el.a.run.app/api/gcd/createUploadSignedURL",
        {}
      )
      .then(async (response) => {
        let r1 = await axios.put(response.data.signedURL, audioFile, {
          "Access-Control-Allow-Origin": "*",
        });

        toast.success("Audio Uploaded Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        let r2 = await axios
          .post(
            "https://aiforall-5psafurj4q-el.a.run.app/api/wrapper/audioToNotes",
            JSON.stringify({ fileName: response.data.fileName }),
            {
              headers: {
                "Content-Type": "application/json",
                userID: userID,
              },
            }
          )
          .catch((error) => {
            console.error("Error uploading audio file to the API:", error);
          });
      })
      .catch((error) => {
        toast.error("Error Uploading The Audio!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setFileName("Select File");
      });
  };
  const handleFileChange = async (e) => {
    setFileName(e.target.files[0].name);
    if (e.target.files[0]) {
      const audioBlob = await fetch(
        URL.createObjectURL(e.target.files[0])
      ).then((r) => r.blob());
      const audioFile = new File([audioBlob], "voice.webm", {
        type: "audio/webm",
      });
      setAudioFile(audioFile);
    }
  };
  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div className="">
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            sx={{ color: "#474747", borderColor: "#474747" }}
            startIcon={<CloudUploadIcon />}
          >
            {fileName}
            <VisuallyHiddenInput
              type="file"
              accept="audio/*"
              onChange={handleFileChange}
            />
          </Button>
        </div>
        <div className="uploadAudioButton">
          <Button
            type="submit"
            sx={{ backgroundColor: "#474747", color: "white", padding: "10px" }}
          >
            Upload Audio
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UploadAudio;
