import React from "react";
import Avatar, { genConfig } from "react-nice-avatar";
import Animal from "react-animals";
import "./LandingPageCarousel.css";
import "./../../Sections/ClientSection.css";
import ClientCard from "../ClientCard/ClientCard";
import quote from "../../../Resources/quote.png";
import quote2 from "../../../Resources/quote2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards, Autoplay, Pagination, Navigation } from "swiper/modules";

const LandingPageCarousel = ({ successStories }) => {
  return (
    <Swiper
      effect={"cards"}
      grabCursor={true}
      modules={[EffectCards, Autoplay, Pagination, Navigation]}
      className="mySwiper"
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
    >
      {successStories.map((story) => (
        <>
          <SwiperSlide>
            <div className="sliderCardInfo">
              <div className="clinician-image">
                <Avatar
                  style={{ width: "5rem", height: "5rem" }}
                  {...genConfig(story.name)}
                />
              </div>
              <div className="feedbackWithImages">
                <div style={{ display: "flex" }}>
                  <img src={quote}></img>
                </div>

                <div className="user-feedback">{story.feedback}</div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <img src={quote2}></img>
                </div>
              </div>
              <div className="user-details">
                <div className="clinician-name">{story.name}</div>
                <div className="clinician-designation">{story.designation}</div>
              </div>
            </div>
          </SwiperSlide>
        </>
      ))}
    </Swiper>
  );
};

export default LandingPageCarousel;
