import React, { useEffect, useState } from "react";
import "./RecordingSection.css";
import "./../Utils/SidePanel/SidePanel.css";
import { init } from "vanilla-tilt";
import ClientMeetingsCard from "./ClientMeetingsCard.jsx";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SidePanel from "./../Utils/SidePanel/SidePanel.jsx";
import { MAIN_URL } from "../../Constants/constants.jsx";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import CircularProgress from "@mui/material/CircularProgress";
import noInteractions from "../../Resources/waiting1.svg";

const ClientMeetingsPage = () => {
  const [transcriptions, setTranscriptions] = useState([]);
  const navigate = useNavigate();
  const { clientID } = useParams();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == null && jwtToken == undefined) {
      navigator("/login", { replace: true });
    }
    else{
      let userid = getUserIdFromJWT(jwtToken);
      async function fetchData() {
        let response = await axios.get(
          MAIN_URL + "api/meeting/meetingsByClientID/" + clientID,
          {
            headers: {
              Accept: "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
          }
        );
        setTranscriptions(response.data);
        setShowLoader(true);
      }
      fetchData();
    }
  }, []);

  return (
    <div className="recordingSection-app-container">
      <SidePanel currentActive="Clients" />
      <div className="main-content">
        {!showLoader ? (
          <div className="clientLoaderContainer">
            <CircularProgress sx={{ margin: "0 auto", color: "#C57F8F" }} />
          </div>
        ) : transcriptions.length == 0 ? (
          <div className="noInteractionsImageSection">
            <img src={noInteractions}></img>
            <h1>
              Go, Have an Interaction. Enalpy is eagerly waiting to hear your
              voice!
            </h1>
          </div>
        ) : (
          <ClientMeetingsCard
            transcriptions={transcriptions}
          ></ClientMeetingsCard>
        )}
        {/* {transcriptions.length > 0 && (
          <ClientMeetingsCard
            transcriptions={transcriptions}
          ></ClientMeetingsCard>
        )} */}
      </div>
    </div>
  );
};

export default ClientMeetingsPage;
