// UserProfileBox.jsx
import React from "react";
import "./UserProfileBox.css";
import { useState, useEffect } from "react";
import { logOut } from "../../../Services/Firebase/Auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar, { genConfig } from "react-nice-avatar";
import { getUserIdFromJWT } from "../../../Services/JWT/jwtFeature.js";
import axios from "axios";
import { MAIN_URL } from "../../../Constants/constants.jsx";

const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
  chrome.runtime.sendMessage(extensionId, { jwt }, (response) => {
    if (!response) {
      return response;
    }
  });
};

const UserProfileBox = ({ profilePhoto, userEmail }) => {
  const config = genConfig("Clini");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    showOptions;

    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken != null) {
      let userid = getUserIdFromJWT(jwtToken);
      async function fetchData() {
        let response = await axios.get(
          MAIN_URL + "api/user/userByID/" + userid,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setEmail(response.data.email);
      }
      fetchData();
    } else if (jwtToken == null || jwtToken == undefined) {
      navigate("/login", { replace: true });
    }
  });

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };
  const handleClickOutside = (event) => {
    if (showOptions && !event.target.closest(".user-profile-box")) {
      setShowOptions(false);
    }
  };

  const handleLogout = async () => {
    await logOut();
    toast("Bye, See You Again!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    localStorage.removeItem("noNameJWT");
    try {
      sendTokenToChromeExtension({
        extensionId: "nojoocdiaaejoedhoanhmpaffenfdglk",
        jwt: null,
      });
    } catch (error) {
      console.log(error);
    }
    navigate("/login", { replace: true });
  };
  // Add or remove event listeners
  useEffect(() => {
    if (showOptions) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showOptions]);
  return (

    <div className="user-profile-box" onClick={toggleOptions}>
    <ToastContainer />
      <Avatar style={{ width: "3rem", height: "3rem" }} {...config} />
      <div className="user-email">{email.substring(0, 21) + "..."}</div>
      {showOptions ? (
        <div className="user-options">
          <div className="option-item-contact">
            Contact Us At: team@enalpy.com
          </div>
          <div className="option-item" onClick={handleLogout}>
            Sign Out{" "}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserProfileBox;
