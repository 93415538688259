import React, { useEffect } from "react";
import companyLogo from "../../../Resources/white.jpg";

const RazorpayPayment = () => {
  useEffect(() => {
    loadRazorpay();
  }, []);

  const loadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = handleRazorpayLoad;
    document.body.appendChild(script);
  };

  const handleRazorpayLoad = async () => {
    const options = {
      key: "rzp_test_MHy2g5HsMkrUzI", // Replace with your key
      amount: 123400,
      currency: "INR",
      name: "Enalpy",
      description: "Test Transaction",
      image: companyLogo,
      handler: function (response) {
        // handle the payment success
        alert(
          `Payment successful! Payment ID: ${response.razorpay_payment_id}`
        );
      },
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9179346161",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#F37254",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div>
      <form>
        <script
          src="https://checkout.razorpay.com/v1/payment-button.js"
          data-payment_button_id="pl_NUwSnuMbXeq3AF"
          async
        >
          {" "}
        </script>{" "}
      </form>
      <button onClick={handleRazorpayLoad}>Pay with Razorpay</button>
    </div>
  );
};

export default RazorpayPayment;
