import React, { useEffect, useState } from "react";
import "./SetClientModal.css";
import axios from "axios";
import { MAIN_URL } from "../../../Constants/constants.jsx";
import SmallClientCard from "./../ClientCard/SmallClientCard.jsx";
import { getUserIdFromJWT } from "../../../Services/JWT/jwtFeature.js";
import addAclient from "../../../Resources/addAclient.svg";

const SetClientModal = ({ isOpen, onClose, onSubmit, meetingId }) => {
  if (!isOpen) return null;
  const [clientsData, setClientsData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const clientData = Object.fromEntries(formData.entries());
    onSubmit(clientData); // Pass the client data back to the parent component
    // Close the modal
  };
  const setClientForMeeting = async (meetingId, clientId) => {
    let response = await axios.post(
      MAIN_URL + "api/meeting/updateMeetingDetail/" + meetingId,
      { clientID: clientId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status == 200) window.location.reload(false);
    onClose();
  };
  useEffect(() => {
    console.log(clientsData.length);
    let jwtToken = localStorage.getItem("noNameJWT");
    let userID = getUserIdFromJWT(jwtToken);
    async function fetchData() {
      let response = await axios.get(
        MAIN_URL + "api/client/clientByUserID/" + userID,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      setClientsData(response.data);
    }
    fetchData();
  }, []);

  return (  
    <div className="set-modal-backdrop">
{

  clientsData.length==0?
  (
<div className="noclient-modal">
  <p>Currently, you have no clients. Please add a new client in the Clients Section.</p>
  <button type="button" onClick={onClose}>
        Close
  </button>
</div>
):(
      <>
    <div className="set-modal">
      <h4>Choose the client for the meeting</h4>
      <div className="setClientForm">
        <div className="client-list">
          {clientsData.map((client, index) => (
            <SmallClientCard
              key={index}
              {...client}
              setClientForMeeting={() => {
                setClientForMeeting(meetingId, client.id);
              }}
            />
          ))}
        </div>
      </div>
      <button type="button" onClick={onClose}>
        Cancel
      </button>
      </div>
      </>
)}
  </div>
  );
};

export default SetClientModal;
