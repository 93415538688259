import React from "react";
import PropTypes from "prop-types";

const SidebarItem = ({ IconComponent, label, isActive, onClick }) => {
  return (
    <div
      className={`sidebar-item ${isActive ? "clicked" : ""}`}
      onClick={onClick}
    >
      <div className="sidebaritemleft">
        {IconComponent && <IconComponent className="sidebar-icon" />}
      </div>
      <div className="sidebaritemright">
        <p className="sidebar-label">{label}</p>
      </div>
    </div>
  );
};

export default SidebarItem;
