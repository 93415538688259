import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../Services/Firebase/firebaseConfig";
import Button from "@mui/material/Button";
import "./Popup.css";

import "./Loader.css";
import Typewriter from "typewriter-effect";
import { getJWT, isJWTexpired } from "../../../Services/JWT/jwtFeature";
import ErrorIcon from "@mui/icons-material/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../Sections/UploadAudio.css";

const Loader = () => {
  const [user, setUser] = useState();
  const navigator = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if(jwtToken==null || jwtToken == undefined) {
      navigator("/login", { replace: true });
    }
    else{
      navigator("/recordingSection", { replace: true });
    }
   
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      } else {
        // No user is signed in.
        console.log("There is no logged in user");
      }
    });
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  const checkEmailVerified = async () => {
    user.reload();
    if (user.emailVerified == false) {
      toast("Please Verify Your Email!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      let jwtToken = await getJWT(user.uid);
      localStorage.setItem("noNameJWT", jwtToken.token);
      navigator("/recordingSection", { replace: true });
    }
  };

  return (
    <div className="loadingBackground">
      <ToastContainer />
      <div className="popup-container">
        {isVisible && (
          <div className="popup">
            <ErrorIcon style={{ color: "red" }}></ErrorIcon>
            <div className="ErrorDiv">
              <p className="errorMessage">Error</p>
              <p>Please verify the email.</p>
            </div>
          </div>
        )}
      </div>
      <div className="loader"></div>
      <div className="verificationText">
        <Typewriter
          options={{
            strings: [
              "Please verify your Email and then continue. See you on the next side!",
            ],
            autoStart: true,
            loop: true,
            deleteSpeed: 10,
            delay: 40,
          }}
        />
      </div>
      <div className="continue">
        <Button
          variant="contained"
          sx={{
            ":hover": {
              bgcolor: "teal", // theme.palette.primary.main
              color: "white",
            },
            textTransform: "none",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "left",
            gap: "8px",
            marginTop: "60px",
            backgroundColor: "white",
            color: "black",
          }}
          onClick={checkEmailVerified}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
export default Loader;
