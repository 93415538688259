import React, { useState, useEffect, useRef } from "react";
import "./MeetingDetails.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { MAIN_URL } from "../../Constants/constants.jsx";
import CircularProgress from "@mui/material/CircularProgress";
const Summary = ({ meetingId }) => {
  const navigator = useNavigate();
  const [summary, setSummary] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [meetingData, setMeetingData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let response = await axios.get(
        MAIN_URL + "api/meeting/meetingByID/" + meetingId,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      response.data.notes = await JSON.parse(response.data.notes);
      setMeetingData(response.data);
      setSummary(response.data.summary);
      setShowLoader(true);
    }
    fetchData();
  }, []);

  return (
    <div className="fullSummaryDetail">
      {!showLoader ? (
        <div className="clientLoaderContainer">
          <CircularProgress sx={{ margin: "0 auto", color: "#C57F8F" }} />
        </div>
      ) : (
        <div className="meetingsDetialsContainer">
          <div className="meetingsDetialsHeader">
            <div className="meetingsDetialsHeaderTimeSection">
              <h1>{meetingData.meetingName}</h1>
            </div>
            <div>
              <hr
                className="meetingsDetialsHeaderLineBreak"
                color="#fffff5"
              ></hr>
            </div>
            <div className="meetingsDetialsHeaderTimeSection">
              <span>Duration: {meetingData.duration}</span>
              <span>Created at: Fri, 26 Jan 2024</span>
            </div>
          </div>
          <div style={{ marginTop: "2%" }}>
            {summary.map((data) => (
              <div>{data}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
