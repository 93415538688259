import axios from "axios";
import { MAIN_URL } from "../../Constants/constants.jsx";
export const getJWT = async (userID) => {
  try {
    const response = await axios.post(
      MAIN_URL + "api/auth/jwtToken",
       { userID },
      {
        headers: {
          "Content-Type": "application/json",
          //userID: userID,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};

export const isJWTexpired = (jwtToken) => {
  if (jwtToken) {
    const decodedToken = decodeJwtToken(jwtToken);
    if (decodedToken.exp * 1000 < Date.now()) {
      return true;
    }
  } else {
    return false;
  }
};

export const decodeJwtToken = (jwtToken) => {
  // Decode the JWT token
  try {
    const decoded = JSON.parse(atob(jwtToken.split(".")[1]));
    return decoded;
  } catch (error) {
    console.error("Error decoding JWT token:", error);
    return null;
  }
};

export const getUserIdFromJWT = (jwtToken) => {
  const decoded = decodeJwtToken(jwtToken);
  return decoded.sub;
};
