import React from "react";
import LandingPage from "./Components/Landing/Landing";
import Navbar from "./Components/Utils/Navbar/Navbar";
// import Pricing from "./Components/Pricing";
// import Pricing
//import FAQS from "./Components/FAQS";
import FAQS from "./Components/Utils/FAQs/FAQS";
import Blogs from "./Components/Blogs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./Services/LoginPage/LoginPage.jsx";
import SignUpPage from "./Services/SignUpPage/SignUpPage.jsx";
import ErrorPage from "./Components/Utils/Error/Error.jsx";
import RecordingSection from "./Components/Sections/RecordingSection.jsx";
import MyTranscriptionsSection from "./Components/Sections/MyTranscriptionSection.jsx";
import Loader from "./Components/Utils/LoadingPage/Loader";
import MeetingDetailsSection from "./Components/Sections/MeetingDetailsSection.jsx";
import RazorpayPayment from "./Components/Utils/RazorpayPayment/RazorpayPayment.jsx";
import ClientsSection from "./Components/Sections/ClientsSection.jsx";
import ClientMeetingsPage from "./Components/Sections/ClientMeetingsPage.jsx";
import PrivacyPolicy from "./Components/Utils/PrivacyPolicy/PrivacyPolicy.jsx";
import UploadAudio from "./Components/Sections/UploadAudio";
import MakeMyPage from "./Components/Sections/MakeMyPageSection.jsx";
import ServiceSection from "./Components/Sections/ServiceSection.jsx";
import NewServiceSection from "./Components/Sections/NewService.jsx";
import AvailabilitySection from "./Components/Sections/AvailabilitySection";
import BookingSection from "./Components/Sections/BookingSection";
import ProfileSection from "./Components/Sections/ProfileSection.jsx";
import PaymentAndSession from "./Components/Sections/PaymentAndSession.jsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/uploadingAudio" element={<UploadAudio />} />
        {<Route path="/login" element={<LoginPage />} />}
        <Route
          path="/clientMeetings/:clientID"
          element={<ClientMeetingsPage />}
          exact
        />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/" element={<LandingPage />} exact />
        {/* <Route path="/blogs" element={<Blogs />} exact /> */}
        <Route path="/faqs" element={<FAQS />} exact />
        <Route path="/recordingSection" element={<RecordingSection />} exact />
        <Route
          path="/transcriptionsSection"
          element={<MyTranscriptionsSection />}
          exact
        />
        <Route path="/loading" element={<Loader />} exact />
        <Route
          path="/meetingSection/:meetingId"
          element={<MeetingDetailsSection />}
          exact
        />
        <Route path="/payment" element={<RazorpayPayment />} exact />
        <Route
          path="/clients"
          element={<ClientsSection></ClientsSection>}
          exact
        />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} exact />
        {/* <Route path="/availability" element={<AvailabilitySection />} exact /> */}
        {/* <Route path="/bookings" element={<BookingSection />} exact></Route> */}
        {/* <Route path="/myPage" element={<MakeMyPage />} exact></Route> */}
        {/* <Route
          path="/services"
          element={<ServiceSection></ServiceSection>}
          exact
        ></Route> */}

        {/* <Route
          path="/newService"
          element={<NewServiceSection></NewServiceSection>}
          exact
        ></Route> */}
     
        {/* <Route
          path="/Profile"
          element={<ProfileSection></ProfileSection>}
          exact
        ></Route> */}
        {/* <Route
          path="/PaymentAndSession"
          element={<PaymentAndSession></PaymentAndSession>}
          exact
        ></Route> */}

        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
    </Router>
  );
};
export default App;
