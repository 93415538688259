import React from "react";
import errorImage from "../../../Resources/404.png";
import "../../Landing/Landing.jsx";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div className="errorPage">
      <img src={errorImage}></img>
      <div className="errorContent">
        <h3>So Sorry!!</h3>
        <h4>The page you're looking for isn't found 🥺</h4>
        <h4 style={{ marginTop: "5px" }}>Let's take you home</h4>
        <div className="homeButton" onClick={handleClick}>
          Home
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
