// src/App.js
import React, { useEffect, useRef } from "react";
import imageSrc2 from "../../Resources/dashboard1.png";
import onlineMeeting from "../../Resources/meetingOnline.png";
import transcriptionMeeting from "../../Resources/transcriptionMeeting.png";
import graphs from "../../Resources/graphs.png";
import Footer from "../Utils/Footer/Footer";
import SuccessStories from "../Utils/SuccessStories/SuccessStories";
import getStartedImage from "../../Resources/getStarted.png";
import "./Landing.css";
import Navbar from "../Utils/Navbar/Navbar";
import googlemeet from "../../Resources/googleMeet.png";
import zoom from "../../Resources/zoom.png";
import teams from "../../Resources/teams.png";
import slack from "../../Resources/slack.png";
import { motion, useInView, useAnimation } from "framer-motion";

const LandingPage = () => {
  const list = { hidden: { opacity: 0, y: 75 }, visible: { opacity: 1, y: 0 } };
  const list2 = {
    hidden: { opacity: 0, x: 80 },
    visible: { opacity: 1, x: 0 },
    hiddenLeft: { opacity: 0, x: -80 },
  };
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (inView) {
      mainControls.start("visible");
    }
  }, [inView]);
  const openExtension = () =>{
    window.location.replace('https://chromewebstore.google.com/detail/enalpy/nojoocdiaaejoedhoanhmpaffenfdglk');
  }
  return ( 
    <>
      <Navbar />
      <div className="landingPage">
        <div className="content">
          <div className="info">
            <div className="inner-content">
              <h2>Understand Your Client Better!</h2>
              <div className="headingblack">
                <p>
                  Convert your conversations into meaningful notes and access
                  them wherever and whenever you need.
                </p>
              </div>
              <div className="headings">
                <p>📋 Clear, Precise And Organized Notes.</p>
                <p>🌐 Seamless Multilingual Conversations.</p>
                <p>⚡ Rapid And Live Client Management.</p>
                <p>
                  🔒 Private And Integrated Collaborations With Several
                  Platforms.
                </p>
              </div>
            </div>
          </div>
          <div className="picture">
            <img src={imageSrc2} alt="Landing Page" />
          </div>
        </div>
        <motion.div
          ref={ref}
          variants={list}
          initial="hidden"
          animate={mainControls}
          className="meetings"
          transition={{ delay: 0.25, duration: 0.5 }}
        >
          <h1>Works Everywhere You Want!</h1>
          <div className="meetingsIcons">
            <div className="meetingIcon">
              <img src={googlemeet}></img>
            </div>
            <div className="meetingIcon">
              <img src={zoom}></img>
            </div>
            <div className="meetingIcon">
              <img src={teams}></img>
            </div>
            <div className="meetingIcon">
              <img src={slack}></img>
            </div>
          </div>
        </motion.div>
        {/* <SuccessStories></SuccessStories> */}
        <div className="features">
          <h1 style={{ fontSize: "56px" }}>
            Your AI Enabled Intelligent Assistant
          </h1>
          <div className="feature">
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureContent"
            >
              <h1>Suitable For Any Type Of Session </h1>

              <p>
                Get AI generated notes of the sessions such that you never miss,
                what you and your client talked about during the session.
              </p>
            </motion.div>
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureImage"
            >
              <img src={onlineMeeting}></img>
            </motion.div>
          </div>
          <div className="feature middle">
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureImage"
            >
              {<img src={transcriptionMeeting}></img>}
            </motion.div>
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureContent"
            >
              <h1>Enalpy Manages Your Clients Seamlessly</h1>
              <p>
                Enalpy never lets you forget about your client's info. You can
                always add new clients, Notify your clients and Take a quick
                view of the previous session's notes before a new session.
              </p>
            </motion.div>
          </div>
          <div className="feature">
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureContent"
            >
              <h1>Client Based Statistical Analysis</h1>
              <p>
                We offer statistical analysis for each of your client's session.
                Our AI assistant analyses your client's behaviour in each
                session so that you can measure your and their progress more
                efficiently.
              </p>
            </motion.div>
            <motion.div
              variants={list}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ delay: 0, duration: 0.6 }}
              className="featureImage"
            >
              <img src={graphs}></img>
            </motion.div>
          </div>
          <div className="getStarted">
            <div className="getStartedContent">
              <div className="getStartedImage">
                <img src={getStartedImage}></img>
              </div>
              <h5 style={{ color: "#5038ee", marginBlockEnd: "0px" }}>
                GET STARTED
              </h5>
              <div className="getStartedHeading">
                <h1>
                  It's never been this easy to get analytics of a conversation.
                </h1>
              </div>
              <div className="getStartedButton" onClick={openExtension}>
                <p>Add Chrome Extension</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
