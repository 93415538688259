import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import googleIcon from "../../Resources/Images/GoogleIcon.png";
import "./LoginPage.css";
import "../LoginPage/LoginPage.css";
import { login, loginWithGoogle } from "../../Services/Firebase/Auth";
import { getJWT } from "../../Services/JWT/jwtFeature";
import loginPagePhoto from "../../Resources/abstract.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

const LoginPage = () => {
  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken != null || jwtToken != undefined) {
      navigator("/recordingSection", { replace: true });
    }
  }, []);

  const handleLogin = async () => {
    try {
      setShowLoader(true);
      let existingUser = await login(email, password);
      let jwtToken = await getJWT(existingUser.user.uid);
      localStorage.setItem("noNameJWT", jwtToken);
      if (existingUser) {
        navigator("/recordingSection");
        setShowLoader(false);
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e.message);
      if (e.message == "Firebase: Error (auth/invalid-email).") {
        toast("Please Enter A Valid Email Id!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/missing-password).") {
        toast("Please Enter Your Password!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/invalid-credential).") {
        toast("Invalid Credentials, Please Try Again!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast("Login Failed, Please Try Again!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const handleLoginWithGoogle = async () => {
    let existingUser;
    try {
      setShowLoader(true);
      existingUser = await loginWithGoogle();
      let jwtToken = await getJWT(existingUser.user.uid);
      localStorage.setItem("noNameJWT", jwtToken.token);

      if (existingUser) {
        navigator("/recordingSection", { replace: true });
        setShowLoader(false);
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e.message);
      toast("Login Failed, Please Try Again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="loginPageContent">
        { showLoader ? (
          <div className="clientLoaderContainer">
            <CircularProgress sx={{ margin: "0 auto", color: "#FFD0EC" }} />
          </div>
        ) : (
          <>
            <div className="leftContainerLogin">
              <div className="formContainerStyle">
                <h3 style={{ marginTop: "0px" }}>Welcome Back!!</h3>
                <h1>To Enalpy</h1>
                <p>Please login to your account.</p>
                <form className="formStyle">
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ marginTop: "50px" }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    sx={{
                      color: "white",
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ marginTop: "50px", color: "white" }}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleLogin}
                    sx={{ backgroundColor: "white", color: "#151125" }}
                    style={{ margin: "30px auto", width: "60%" }}
                  >
                    Login
                  </Button>
                  <Typography
                    variant="body2"
                    style={{ margin: "16px auto 0", color: "#777" }}
                  >
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className="login-link"
                      underline="always"
                    >
                      Sign up here
                    </Link>
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "white",
                      ":hover": { color: "#151125" },
                    }}
                    startIcon={
                      <img
                        alt="Google Icon"
                        src={googleIcon}
                        className="googleIcon"
                      />
                    }
                    fullWidth
                    onClick={handleLoginWithGoogle}
                    style={{ margin: "20px auto", width: "80%" }}
                  >
                    Continue with Google
                  </Button>
                </form>
              </div>
            </div>
            <div className="image">
              <img src={loginPagePhoto} alt="" className="AccountTypeimage" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default LoginPage;
