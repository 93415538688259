/*global chrome*/
import React, { useEffect, useState } from "react";
import "./RecordingSection.css";
import "./../Utils/SidePanel/SidePanel.css";
import { init } from "vanilla-tilt";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Recorder from "../../Services/Recorder/Recorder.jsx";
import Calendar from "./CalendarSection.jsx";
import { useNavigate } from "react-router-dom";
import SidePanel from "./../Utils/SidePanel/SidePanel.jsx";

const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
  chrome.runtime.sendMessage(extensionId, { jwt }, (response) => {
    return response;
  });
};

const RecordingSection = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == undefined || jwtToken == null) {
      navigate("/login");
    } else {
      try {
        sendTokenToChromeExtension({
          extensionId: "nojoocdiaaejoedhoanhmpaffenfdglk",
          jwt: jwtToken,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  const pull_data = (data) => {
    setTranscription((e) => [...e, data]);
  };
  return (
    <div className="recordingSection-app-container">
      <SidePanel currentActive={"Recorder"}></SidePanel>
      <div className="main-content">
        <div className="center-content">
          <Recorder />
        </div>
      </div>
    </div>
  );
};

export default RecordingSection;
