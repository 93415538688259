import {
  auth,
  googleProvider,
  firestore,
} from "../../Services/Firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MAIN_URL } from "../../Constants/constants.jsx";

export const signIn = async (email, password) => {
  try {
    let newUserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    if (newUserCredential) {
      const docRef = await setDoc(
        doc(firestore, "User", newUserCredential.user.uid),
        {
          email: newUserCredential.user.email,
          userID: newUserCredential.user.uid,
          subscribed : false,
          subscriptionExpiryDate : null,
        }
      );
      await sendEmailVerification(newUserCredential.user);
    }

    return newUserCredential;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};

export const signUpWithGoogle = async () => {
  try {
    let newUserCredential = await signInWithPopup(auth, googleProvider);

    if (newUserCredential) {
      const docRef = await setDoc(
        doc(firestore, "User", newUserCredential.user.uid),
        {
          email: newUserCredential.user.email,
          userID: newUserCredential.user.uid,
          subscribed : false,
          subscriptionExpiryDate : null,
        }
      );
    }
    return newUserCredential;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const loginWithGoogle = async () => {
  let existingUser;

  try {
    existingUser = await signInWithPopup(auth, googleProvider);

    let response = await axios.get(
      MAIN_URL + "api/user/userByID/" + existingUser.user.uid,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.error) {
      const docRef = await setDoc(
        doc(firestore, "User", existingUser.user.uid),
        {
          email: existingUser.user.email,
          userID: existingUser.user.uid,
          subscribed : false,
          subscriptionExpiryDate : null,
        }
      );
    }
    return existingUser;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const login = async (email, password) => {
  try {
    let existingUser = await signInWithEmailAndPassword(auth, email, password);
    if (existingUser) {
    }
    return existingUser;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const logOut = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
