import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider,sendSignInLinkToEmail,onAuthStateChanged} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyD6xEOQDsWFs6O5dyNLBlEHTXCpiJhQCWU",
  authDomain: "projecty-9464d.firebaseapp.com",
  projectId: "projecty-9464d",
  storageBucket: "projecty-9464d.appspot.com",
  messagingSenderId: "598001889242",
  appId: "1:598001889242:web:b1bd79e9d21be46dd51ae3",
  measurementId: "G-743P9S2EB9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const firestore = new getFirestore(app);
