import React, { useEffect, useState } from "react";
import "./RecordingSection.css";
import "../Utils/SidePanel/SidePanel.css";
import MeetingDetails from "./MeetingDetails.jsx";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import SidePanel from "../Utils/SidePanel/SidePanel.jsx";
import Summary from "./SummarySection.jsx";
const MeetingDetailsSection = () => {
  const [isNotesSection, setIsNotesSection] = useState(true);
  const navigate = useNavigate();
  const { meetingId } = useParams();
  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == undefined || jwtToken == null) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="recordingSection-app-container">
      <SidePanel currentActive={"My Interactions"}></SidePanel>
      <div className="main-content">
        <div className="meetingDetailsSectionContent">
          <div className="meetingContentHeadingSection">
            <IconButton
              onClick={() => {
                navigate(-1, { replace: true });
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            <div className="optionDivs">
              <div
                onClick={() => setIsNotesSection(true)}
                className={`sectionButton ${isNotesSection ? "active" : ""}`}
              >
                Notes
              </div>
              <div
                className={`sectionButton ${!isNotesSection ? "active" : ""}`}
                onClick={() => setIsNotesSection(false)}
              >
                Summary
              </div>
            </div>
          </div>
          {isNotesSection ? (
            <MeetingDetails meetingId={meetingId}></MeetingDetails>
          ) : (
            <Summary meetingId={meetingId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetingDetailsSection;
