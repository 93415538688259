import Link from "@mui/material/Link";
import logo from "../../../Resources/123.jpg";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar">
      <Link className="homelogo" underline="none" href="/">
        <img src={logo}></img>
        <h1>enalpy</h1>
      </Link>

      <div className="navLinkWrapper">
        <Link className="navLink" underline="none" href="/">
          Home
        </Link>
        {/* <Link className="navLink" underline="none" href="/pricing">
          Pricing
        </Link> */}
        <Link className="navLink" underline="none" href="/faqs">
          FAQs
        </Link>
        <Link className="navLink" underline="none" href="/blogs">
          Blogs
        </Link>
      </div>
      <button
        className="loginButton"
        onClick={() => {
          navigate("/login");
        }}
      >
        <Link className="loginbuttoncolor" underline="none" href="/login">
          Login
        </Link>
      </button>
    </div>
  );
};

export default Navbar;
