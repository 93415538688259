import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import "./SignUpPage.css"; // Import the CSS file
import { signIn, signUpWithGoogle, logOut } from "../Firebase/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getJWT } from "../JWT/jwtFeature";
import loginPagePhoto from "../../Resources/abstract.jpeg";
import googleIcon from "../../Resources/Images/GoogleIcon.png";
import { auth } from "../Firebase/firebaseConfig";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");

    if (jwtToken != null && jwtToken != undefined) {
      navigate("/recordingSection", { replace: true });
    }
  }, []);

  const handleSignUp = async () => {
    try {
      let newUserCredential = await signIn(email, password);
      if (newUserCredential) {
        navigate("/loading", { replace: true });
      }
    } catch (e) {
      console.log(e.message);
      if (e.message == "Firebase: Error (auth/email-already-in-use).") {
        toast("Email Already In Use!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/invalid-email).") {
        toast("Please Enter A Valid Email Id!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/missing-password).") {
        toast("Please Enter A Password!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/missing-email).") {
        toast("Please Enter An Email!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (e.message == "Firebase: Error (auth/invalid-credential).") {
        toast("Invalid Credentials, Please Try Again!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (
        e.message ==
        "Firebase: Password should be at least 6 characters (auth/weak-password)."
      ) {
        toast("Password should be at least 6 characters long!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast("Sign-Up Failed, Please Try Again After Some Time!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const handleSignUpwithGoogle = async () => {
    let newUserCredential;

    try {
      newUserCredential = await signUpWithGoogle();
      if (newUserCredential) {
        // const reponse2 = await axios.post(
        //   MAIN_URL + "api/calendar/addCalendar",
        //   {
        //     week: {
        //       0: [{ start: "9:00", end: "17:00" }],
        //       1: [{ start: "9:00", end: "17:00" }],
        //       2: [{ start: "9:00", end: "17:00" }],
        //       3: [{ start: "9:00", end: "17:00" }],
        //       4: [{ start: "9:00", end: "17:00" }],
        //       5: [{ start: "9:00", end: "17:00" }],
        //       6: [{ start: "9:00", end: "17:00" }],
        //     },
        //   },
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       userID: newUserCredential.user.uid,
        //     },
        //   }
        // );
        let jwtToken = await getJWT(newUserCredential.user.uid);
        localStorage.setItem("noNameJWT", jwtToken.token);
        navigate("/recordingSection", { replace: true });
      }
    } catch (e) {
      toast("Sign Up Failed! Please Try Again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="loginPageContent">
        <div className="leftContainerLogin">
          <div className="formContainerStyle">
            <h1 style={{ marginTop: "0px" }}>Welcome</h1>
            <h3 style={{ marginTop: "0px" }}>Join us today</h3>
            <p>Create your account here.</p>
            <form className="formStyle">
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginTop: "50px" }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                sx={{
                  color: "white",
                }}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: "50px", color: "white" }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleSignUp}
                sx={{ backgroundColor: "white", color: "#151125" }}
                style={{ margin: "30px auto", width: "60%" }}
              >
                Sign Up
              </Button>
              <Typography
                variant="body2"
                style={{ margin: "16px auto 0", color: "#777" }}
              >
                Already have an account?{" "}
                <Link to="/login" className="login-link" underline="always">
                  Login here
                </Link>
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  ":hover": { color: "#151125" },
                }}
                startIcon={
                  <img
                    alt="Google Icon"
                    src={googleIcon}
                    className="googleIcon"
                  />
                }
                fullWidth
                onClick={handleSignUpwithGoogle}
                style={{ margin: "20px auto", width: "80%" }}
              >
                Continue with Google
              </Button>
            </form>
          </div>
        </div>
        <div className="image">
          <img src={loginPagePhoto} alt="" className="AccountTypeimage" />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
