import React, { useState } from "react";
import "./RecordingSection.css";
import "./../Utils/SidePanel/SidePanel.css";
import SidePanel from "../Utils/SidePanel/SidePanel.jsx";
import "./NewService.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import { MAIN_URL } from "../../Constants/constants.jsx";
import axios from "axios";

const NewServiceSection = () => {
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [price, setPrice] = useState("");
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const handleShortDescriptionChange = (value) => {
    setShortDescription(value.target.value);
  };
  const handlePriceChange = (value) => {
    setPrice(value.target.value);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value.target.value);
  };
  const handleContentChange = (value) => {
    setContent(value);
  };
  const onSave = async () => {
    let jwtToken = localStorage.getItem("noNameJWT");
    let userID = getUserIdFromJWT(jwtToken);
    let response = await axios.post(
      MAIN_URL + "api/event/addEvent",
      JSON.stringify({
        description: description,
        duration: 150,
        price: price,
        subDescription: shortDescription,
        userID: userID,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          userID: userID,
        },
      }
    );
    console.log(response.data);
    location.reload();
  };
  return (
    <div className="recordingSection-app-container">
      <SidePanel currentActive="Services" />
      <div className="main-content">
        <div className="upperEventsHeading2">
          <IconButton
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <h1>Add service</h1>
        </div>
        <hr></hr>
        <div className="newServiceForm">
          <div className="form-group">
            <label className="serviceTitle">Title</label>
            <input
              type="text"
              id="serviceTitle"
              className="form-control"
              placeholder="Name Of The Service"
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label className="serviceTitle">Short Description</label>
            <input
              type="text"
              id="shortDescription"
              className="form-control"
              placeholder="It will be visible below your service title"
              onChange={handleShortDescriptionChange}
            />
          </div>
          <div className="form-group">
            <label className="serviceTitle">Content</label>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={handleContentChange}
            />
          </div>
          <div className="form-group">
            <label className="serviceTitle">Pricing</label>
            <input
              type="text"
              id="pricing"
              className="form-control"
              placeholder="Enter Price"
              onChange={handlePriceChange}
            />
          </div>
          <div
            className="newEventButton"
            style={{ alignSelf: "center" }}
            onClick={onSave}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewServiceSection;
