import React from "react";
import Navbar from "../Navbar/Navbar";

// Component with inline styles
const PrivacyPolicy = () => {
  // Inline CSS styles
  const privacyPolicyStyle = {
    color: "white",
    padding: "20px",
    backgroundColor: "#151125",
    lineHeight: "1.6",
    fontFamily: "Arial, sans-serif",
  };

  return (
    <>
      <Navbar />
      <div style={privacyPolicyStyle}>
        <h1 style={{ textAlign: "center" }}>Privacy Policy for Enalpy</h1>

        <section>
          <h2>Data Collection and Use</h2>
          <p>
            We collect the following types of information for various purposes
            to provide and improve our extension:
          </p>

          <h3>Text-based Data</h3>
          <ul>
            <li>
              <strong>User Information</strong>: Includes your name, email
              address, and other contact information you provide during the
              account creation process.
            </li>
            <li>
              <strong>Authentication Data</strong>: Managed through Firebase,
              includes password and authentication tokens for securing your
              account.
            </li>
            <li>
              <strong>Preferences and Settings</strong>: Your customized
              settings and preferences within the extension.
            </li>
          </ul>

          <h3>Technical Data</h3>
          <ul>
            <li>
              <strong>Usage Data</strong>: Information on how you access and use
              the extension, including your interaction with the extension's
              interface.
            </li>
            <li>
              <strong>Device and Connection Information</strong>: We collect
              information about the devices accessing our services, including IP
              addresses, browser types, and versions used.
            </li>
          </ul>
        </section>

        <section>
          <h2>Data Storage and Processing</h2>
          <p>
            Information on how we store and process your data, including details
            about Firebase and Google Cloud Run (GCR).
          </p>
        </section>

        <section>
          <h2>Data Sharing and Disclosure</h2>
          <p>
            Details on how we do not sell, trade, or rent your personal
            information to others, and under what circumstances we may share
            information.
          </p>
        </section>

        <section>
          <h2>Your Data Protection Rights</h2>
          <p>
            Information on your rights to access, update, or delete your
            information in our possession.
          </p>
        </section>

        <section>
          <h2>Data Security</h2>
          <p>
            Our commitment to protecting your data through appropriate security
            measures.
          </p>
        </section>

        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>How we will notify you about changes to this privacy policy.</p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at team@enalpy.com
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
