import React, { useEffect, useState } from "react";
import "./TranscriptionSections.css";
import { useNavigate } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";
import CancelIcon from "@mui/icons-material/Cancel";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Button from "@mui/joy/Button";
import SetClientModal from "./../Utils/Modals/SetClientModal.jsx";
const MyTranscriptions = ({ transcriptions }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");

    if (jwtToken == null || jwtToken == undefined) {
      navigate("/login", { replace: true });
    }
  }, [isModalOpen]);

  const selectAClient = () => {
  };

  function navigateToMeeting(meetingId) {
    navigate(`/meetingSection/${meetingId}`);
  }

  return (
    <div className="transcriptionsContainer">
      <div className="transcriptionsHeading">
        <h2>Interactions</h2>
        <hr className="meetingsDetialsHeaderLineBreak"></hr>
      </div>
      <div className="transcriptionsBody">
        {transcriptions.map((transcription, index) => (
          <div
            key={index}
            className="transcriptionCard"
            style={{ backgroundColor: index % 2 ? "white" : "#f8f8f8" }}
          >
            <div className="transcriptionCardDate">
              <GroupsIcon
                sx={{ width: "1.5em", height: "1.5em", color: "#44474B" }}
              />
              {/* <p>{transcription.time}</p> */}
              <p>{transcription.time}</p>
            </div>
            {/* <p>{transcription.meetingName}</p> */}
            <div className="transcriptionCardName">
              <p>{transcription.meetingName}</p>
              <p>{transcription.duration}</p>
            </div>
            <div className="transcriptionCardButtons">
              <Button
                variant="outlined"
                sx={{ color: "#808080", borderColor: "#808080" }}
                onClick={() => navigateToMeeting(transcription.id)}
              >
                Show Notes
              </Button>
              <Button
                variant="outlined"
                sx={{ color: "#808080", borderColor: "#808080" }}
                onClick={() => {
                  setCurrentMeetingId(transcription.id);
                  setIsModalOpen(true);
                }}
              >
                Set Client
              </Button>
              {/* <EditSharpIcon
                sx={{ width: "1.3em", height: "1.3em", color: "#44474B" }}
              />
              <CancelIcon
                sx={{ width: "1.3em", height: "1.3em", color: "#44474B" }}
              /> */}
            </div>
            <SetClientModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              meetingId={currentMeetingId}
              //onSubmit={selectAClient}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyTranscriptions;
