import React from "react";
import "./SmallClientSection.css";
import Avatar, { genConfig } from "react-nice-avatar";

const SmallClientCard = ({ name, email, setClientForMeeting }) => {
  const config = genConfig(name);
  return (
    <div
      className="small-client-card"
      onClick={() => {
        setClientForMeeting();
      }}
    >
      <Avatar style={{ width: "3rem", height: "3rem" }} {...config} />
      <div className="small-client-details">
        <p style={{ fontWeight: "bold" }}>{name}</p>
        <p style={{ marginTop: "5px" }}>{email.substring(0, 21) + "..."}</p>
      </div>
    </div>
  );
};

export default SmallClientCard;
