import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./../../Landing/Landing.css";
import { motion, useInView, useAnimation } from "framer-motion";

const FAQS = () => {
  const list = { hidden: { opacity: 0, y: 75 }, visible: { opacity: 1, y: 0 } };
  return (
    <>
      <Navbar />
      <div>
        <div className="faq">
          <div className="faqMainContainer">
            <div className="leftFaqHeading">
              <div className="headingContent">
                <p>FAQs</p>
                <h1>Got Questions? We've Got Answers!</h1>
              </div>
            </div>
            <div className="faqContent">
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4>How do I get started with Enalpy?</h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    To get started with Enalpy, simply sign up on our website,
                    follow the installation instructions for the browser
                    extension, and start conducting secure therapy sessions with
                    ease. Your clients' data privacy and security are our top
                    priority.
                  </p>
                </div>
              </motion.div>
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4>What is the primary function of Enalpy?</h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    Enalpy is a specialized tool for psychotherapists that
                    offers a browser extension allowing therapists to start and
                    record therapy sessions on various meeting platforms (Google
                    Meet, Teams, Zoom). It also provides features like session
                    notes, meeting insights, and secure client data storage for
                    efficient therapy management.
                  </p>
                </div>
              </motion.div>
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4> Can I cancel my subscription?</h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    Yes, you can cancel your subscription at any time by
                    accessing your account settings and following the provided
                    cancellation instructions.
                  </p>
                </div>
              </motion.div>
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4>
                    Is Enalpy compatible with all meeting platforms, including
                    offline use?
                  </h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    Yes, Enalpy is designed to be compatible with all major
                    meeting platforms, providing seamless integration and the
                    option for offline functionality to enhance your therapy
                    sessions.
                  </p>
                </div>
              </motion.div>
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4>
                    How does Enalpy prioritize privacy and security for my
                    therapy sessions and client data?
                  </h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    Enalpy places a paramount emphasis on privacy and security.
                    We employ advanced encryption and access controls to ensure
                    the utmost protection of your therapy sessions and client
                    data, maintaining confidentiality and trust in our platform.
                  </p>
                </div>
              </motion.div>
              <motion.div
                variants={list}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: 0, duration: 0.6 }}
                className="faqItem"
              >
                <div className="faqQuestion">
                  <h4>
                    What is the duration of the free session offered by Enalpy?
                  </h4>
                </div>
                <div className="faqAnswer">
                  <p>
                    The duration of the free session with Enalpy is provided
                    according to the terms and conditions specified during your
                    registration or trial period. Please refer to the
                    information provided at the time of sign-up for the exact
                    duration.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQS;
