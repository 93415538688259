import React from "react";
import "./../../Sections/ClientSection.css";
import Avatar, { genConfig } from "react-nice-avatar";
import Animal from "react-animals";
import Button from "@mui/joy/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserIdFromJWT } from "../../../Services/JWT/jwtFeature.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MAIN_URL } from "../../../Constants/constants.jsx";

const ClientCard = ({ name, title, email, id }) => {
  const navigate = useNavigate();
  const [userID, setuserID] = useState("");
  const navigateToClientsMeeting = (clientId) => {
    navigate(`/clientMeetings/${clientId}`);
  };

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == null || jwtToken == undefined) {
      navigate("/login");
    } else {
      let userID = getUserIdFromJWT(jwtToken);
      setuserID(userID);
    }
  }, []);

  let clients = [];

  async function remindClientWithEmail() {
    // let userID = userID;

    clients.push(email);
    try {
      let response = await axios.post(
        MAIN_URL + "api/email/sendEmail",
        { receivers: clients,userID: userID},
        {
          headers: {
            "Content-type": "application/json",
            // userID: userID
          },
        }
      );

      if (response.status == 200) {
        toast.success("Notification Sent!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (e) {
      console.log(e.message);
      toast.error("Please Try Again!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  const config = genConfig(name);
  return (
    <div className="client-card">
      <ToastContainer />
      <div className="client-card-content">
        <div className="card-heading">
          <Avatar style={{ width: "3rem", height: "3rem" }} {...config} />
          <div className="card-heading-name">
            <h3>{name}</h3>
            <p>{title}</p>
            <p>{email}</p>
          </div>
        </div>
        <hr className="cutting-line"></hr>
        <div className="client-card-details">
          <Button
            variant="outlined"
            sx={{ color: "#808080", borderColor: "#808080" }}
            onClick={() => navigateToClientsMeeting(id)}
          >
            Meetings
          </Button>
          <Button
            variant="outlined"
            sx={{ color: "#808080", borderColor: "#808080" }}
            onClick={() => remindClientWithEmail()}
          >
            Remind
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
