import React, { useEffect, useState } from "react";
// import './App.css';
import MicRecorder from "mic-recorder-to-mp3";
import axios from "axios";
import "./Recorder.css";
import { MAIN_URL } from "../../Constants/constants.jsx";
import mic from "../../Resources/mic.png";
import wave from "../../Resources/whileRecording.png";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import UploadAudio from "../../Components/Sections/UploadAudio";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
import { useNavigate } from "react-router-dom";

const Recorder = ({ func }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [timer, setTimer] = useState(0); // Tracks the timer's current value
  const [timerInterval, setTimerInterval] = useState(null); // Keeps the interval function
  const [userID, setUserID] = useState(null);
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();
  const [apiURL, setAPIURL] = useState("");

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == null || jwtToken == undefined) {
      navigate("/login", { replace: true });
    } else {
      let userid = getUserIdFromJWT(jwtToken);
      setUserID(userid);
    }
  }, []);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
          setTimer(0); // Reset timer to 0
          setTimerInterval(
            setInterval(() => {
              setTimer((prevTimer) => prevTimer + 1); // Increment timer every second
            }, 1000)
          );
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    clearInterval(timerInterval);
    setTimerInterval(null);
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setIsRecording(false);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobURL;
        downloadLink.setAttribute("download", "recorded_audio.mp3"); // Set the filename for the download
        document.body.appendChild(downloadLink); // Append to the document
        downloadLink.click(); // Programmatically click the link to start the download
        document.body.removeChild(downloadLink);

        const audioBlob = await fetch(blobURL, {
          mode: "cors",
        }).then((r) => r.blob());
        const audioFile = new File([audioBlob], "voice.webm", {
          type: "audio/webm",
        });

        axios
          .get(
            "https://aiforall-5psafurj4q-el.a.run.app/api/gcd/createUploadSignedURL",
            {}
          )
          .then(async (response) => {
            setFileName(response.data.fileName);
            setAPIURL(response.data.signedURL);
            console.log(response.data.signedURL);
            console.log(response.data.fileName);
            console.log(apiURL);

            let r1 = await axios.put(response.data.signedURL, audioFile, {
              "Access-Control-Allow-Origin": "*",
            });
            console.log(r1);
            let r2 = await axios
              .post(
                "https://aiforall-5psafurj4q-el.a.run.app/api/wrapper/audioToNotes",
                JSON.stringify({ fileName: response.data.fileName }),
                {
                  headers: {
                    "Content-Type": "application/json",
                    userID: userID,
                  },
                }
              )
              .catch((error) => {
                console.error("Error uploading audio file to the API:", error);
              });
          });
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="Recorder">
      <div className="recorderHeading">
        <h1>Voice Recorder</h1>
        <p>Record the meeting to get notes</p>
      </div>
      {isRecording ? (
        <>
          <div className="recorderImage">
            <img src={wave}></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              <strong>
                <p>
                  Recording... {Math.floor(timer / 60)}:
                  {("0" + (timer % 60)).slice(-2)}
                </p>
              </strong>
            </p>
            <div className="startRecordingButton" onClick={stopRecording}>
              Stop Recording
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="recorderImage">
            <img src={mic}></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              To start recording, please press the{" "}
              <strong>"Start Recording"</strong> button below.
            </p>
            <div className="startRecordingButton" onClick={startRecording}>
              Start Recording
            </div>
          </div>
          <UploadAudio />
        </>
      )}

      <div className="recorderFooter">
        <div className="recorderFooterSection">
          <div className="recorderFooterHeading">Downloadable</div>
          <div className="recorderFooterContent">
            Effortlessly download your session recordings for secure, anytime
            access. Keep a permanent record with just a few clicks.
          </div>
        </div>
        <div className="recorderFooterSection">
          <div className="recorderFooterHeading">Get Notes for Meetings</div>
          <div className="recorderFooterContent">
            Instantly receive detailed notes after each session ends. Focus on
            your client, we'll handle the documentation.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recorder;
