import React from "react";
import "./Footer.css";
import { IoLogoInstagram } from "react-icons/io5";
import companyLogo from "../../../Resources/white.jpg";
import { RiLinkedinBoxLine } from "react-icons/ri";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="upper">
          <div className="left-side">
            <div className="logoFooter">
              <img src={companyLogo}></img>
            </div>

            <div className="company-name-footer">enalpy</div>
          </div>
          <div className="right-side">
            <div className="infos">
              <div className="upper-two">
                <div className="list">
                  <h4>PLATFORM</h4>
                  <ul>
                    <li>Your Intelligent </li>
                    <li>AI Assistant</li>
                  </ul>
                </div>
                <div className="list">
                  <h4>SUPPORT</h4>
                  <ul>
                    <li>team@enalpy.com</li>
                  </ul>
                </div>
                <div className="list">
                  <h4>COMPANY</h4>
                  <ul>
                    <li>An Enalpy Inc.</li>
                    <li>Registered Company</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="social-media">
              <h4>FOLLOW US</h4>
              <div className="social-icons">
                <IoLogoInstagram
                  size={30}
                  style={{ marginTop: "2px" }}
                ></IoLogoInstagram>
                <RiLinkedinBoxLine size={35}></RiLinkedinBoxLine>
              </div>
              <address className="company-address">
                <p>Gachibowli Hyderabad, Telangana</p>
                <p>500032</p>
              </address>
            </div>
          </div>
        </div>
        <hr className="seprator"></hr>
        <div className="copy-right">
          &copy; 2024 Enalpy Inc. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
