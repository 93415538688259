import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import chromelogo from "../../../Resources/chromeicon.png";
import MicNoneIcon from "@mui/icons-material/MicNone";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Book from "@mui/icons-material/Book";
import MenuIcon from "@mui/icons-material/Menu";
import companyLogo from "../../../Resources/white.jpg";
import UserProfileBox from "./UserProfileBox.jsx";
import profilePhotofirst from "../../../Resources/profilephoto.jpeg";
import SidebarItem from "./SideBarItem.jsx";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { MicNone } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const SidePanel = ({ currentActive }) => {
  const [activeItem, setActiveItem] = useState(currentActive);
  const navigate = useNavigate();
  const handleItemClick = (item) => {
    navigate(item);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const openExtension = () => {
    window.location.replace(
      "https://chromewebstore.google.com/detail/enalpy/nojoocdiaaejoedhoanhmpaffenfdglk"
    );
  };
  useEffect(() => {});
  return (
    <>
      <div className="hamburger-menu" onClick={toggleSidebar}>
        {/* Icon can be an SVG, an image, or a font icon */}
        <MenuIcon />
      </div>
      <div
        className={`overlay ${sidebarOpen ? "active" : ""}`}
        onClick={closeSidebar}
      ></div>
      <div className={`side-panel ${sidebarOpen ? "open" : ""}`}>
        <div className="company-section">
          <img src={companyLogo} alt="Company Logo" className="company-logo" />
          <h1 className="company-name">enalpy</h1>
        </div>

        <UserProfileBox
          profilePhoto={profilePhotofirst}
          userEmail="user@example.com"
        />
        <SidebarItem
          IconComponent={AccountCircleIcon}
          label="Profile"
          isActive={activeItem === "Profile"}
          onClick={() => handleItemClick("/Profile")}
        ></SidebarItem>
        <SidebarItem
          IconComponent={MicNoneIcon}
          label="Recorder"
          isActive={activeItem === "Recorder"}
          onClick={() => handleItemClick("/RecordingSection")}
        />
        {/* <SidebarItem
          IconComponent={AccountBalanceIcon}
          label="Payment&Session"
          isActive={activeItem === "PaymentAndSession"}
          onClick={() => handleItemClick("/PaymentAndSession")}
        ></SidebarItem> */}
        <SidebarItem
          IconComponent={HandshakeIcon}
          label="Clients"
          isActive={activeItem === "Clients"}
          onClick={() => handleItemClick("/clients")}
        />
        <SidebarItem
          IconComponent={ForumIcon}
          label="My Interactions"
          isActive={activeItem === "My Interactions"}
          onClick={() => handleItemClick("/transcriptionsSection")}
        />
        {/* <SidebarItem
          IconComponent={SelfImprovementIcon}
          label="Services"
          isActive={activeItem === "Services"}
          onClick={() => handleItemClick("/services")}
        /> */}
        {/* <SidebarItem
          IconComponent={CalendarMonthIcon}
          label="Availability"
          isActive={activeItem === "Availability"}
          onClick={() => handleItemClick("/availability")}
        /> */}

        {/* <SidebarItem
          IconComponent={BookOnlineIcon}
          label="Bookings"
          isActive={activeItem === "Bookings"}
          onClick={() => handleItemClick("/bookings")}
        /> */}
        {/* <SidebarItem
          IconComponent={TextSnippetOutlinedIcon}
          label="Case Studies"
          isActive={activeItem === "Case Studies"}
          onClick={() => handleItemClick("/caseStudies")}
        /> */}
        {/* <div
          onClick={() => {
            navigate("/myPage");
          }}
          className="getToMyPage"
        >
          Get to My page
        </div> */}
        <hr className="sidepanelline"></hr>
        <div className="getExtension" onClick={openExtension}>
          <img src={chromelogo} className="company-logo" />
          <div className="extensionText">
            <p>Get Web Extension</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
