// NewClientModal.jsx
import React from "react";
import "./NewClientModal.css";

const NewClientModal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const clientData = Object.fromEntries(formData.entries());
    onSubmit(clientData); // Pass the client data back to the parent component
    onClose(); // Close the modal
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <form className="clientForm" onSubmit={handleSubmit}>
          <h2>Add New Client</h2>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />

          <button type="submit">Add Client</button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewClientModal;
