import React, { useEffect, useState } from "react";
import "./RecordingSection.css";
import "./../Utils/SidePanel/SidePanel.css";
import { init } from "vanilla-tilt";
import Calendar from "./CalendarSection.jsx";
import MyTranscriptions from "./MyTranscriptions.jsx";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SidePanel from "../Utils/SidePanel/SidePanel.jsx";
import { MAIN_URL } from "../../Constants/constants.jsx";
import { getUserIdFromJWT } from "../../Services/JWT/jwtFeature.js";
import noInteractions from "../../Resources/waiting1.svg";
import CircularProgress from "@mui/material/CircularProgress";

const MyTranscriptionsSection = () => {
  const [transcriptions, setTranscriptions] = useState([]);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    let jwtToken = localStorage.getItem("noNameJWT");
    if (jwtToken == undefined || jwtToken == null) {
      navigate("/login");
    } else {
      let userid = getUserIdFromJWT(jwtToken);
      async function fetchData() {
        let response = await axios.get(
          MAIN_URL + "api/meeting/meetingsByUserID/" + userid,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        response = response.data.filter((data) => {
          return data["clientID"] === "";
        });
        setTranscriptions(response);
        setShowLoader(true);
      }
      fetchData();
    }
  }, []);

  return (
    <div className="recordingSection-app-container">
      <SidePanel currentActive="My Interactions" />
      <div className="main-content">
        {!showLoader ? (
          <div className="clientLoaderContainer">
            <CircularProgress sx={{ margin: "0 auto", color: "#C57F8F" }} />
          </div>
        ) : transcriptions.length == 0 ? (
          <div className="noInteractionsImageSection">
            <img src={noInteractions}></img>
            <h1>
              Go, Have an Interaction. Enalpy is eagerly waiting to hear your
              voice!
            </h1>
          </div>
        ) : (
          <MyTranscriptions transcriptions={transcriptions}></MyTranscriptions>
        )}
      </div>
    </div>
  );
};

export default MyTranscriptionsSection;
